// extracted by mini-css-extract-plugin
export var link = "index-module--link--1Un0D";
export var h1 = "index-module--h1--vaVG6";
export var h2 = "index-module--h2--3lnhO";
export var h3 = "index-module--h3--2C4t4";
export var h4 = "index-module--h4--13vbO";
export var h5 = "index-module--h5--_zRPB";
export var h6 = "index-module--h6--3PAib";
export var p = "index-module--p--27mZI";
export var credit = "index-module--credit--2xqil";
export var figcaption = "index-module--figcaption--3I7Cl";
export var footer = "index-module--footer--1Lu6x";
export var buttonTextAction = "index-module--buttonTextAction--3ZtWV";
export var button = "index-module--button--2YDWh";
export var buttonTextFooter = "index-module--buttonTextFooter--6ANCM";
export var buttonTextFilter = "index-module--buttonTextFilter--hYyOs";
export var listItemTouchwall = "index-module--listItemTouchwall--3USFa";
export var listNumberTouchwall = "index-module--listNumberTouchwall--2K_cn";
export var popupNumber = "index-module--popupNumber--3phhf";
export var creditsPage = "index-module--creditsPage--2eTLO";
export var textColorWhite = "index-module--textColorWhite--odFeK";
export var textColorBlack = "index-module--textColorBlack--2pF2y";
export var strong = "index-module--strong--3xrcp";
export var noDecoration = "index-module--noDecoration--1Nr3k";
export var gatsbyFocusWrapper = "index-module--gatsby-focus-wrapper--2gp3K";
export var sixteenNineBox = "index-module--sixteenNineBox--25zOS";
export var fourThreeBox = "index-module--fourThreeBox--BySyo";
export var sixteenNineBoxInner = "index-module--sixteenNineBoxInner--2EIkw";
export var btnDisabled = "index-module--btnDisabled--125gq";
export var mapMuralExplore = "index-module--mapMuralExplore--3ddkH";
export var toggleButton = "index-module--toggleButton--1FAow";
export var toggleButtonActive = "index-module--toggleButtonActive--1pyCF";
export var inlineIcon = "index-module--inlineIcon--1N2y-";
export var flexContainer = "index-module--flexContainer--v8uZh";
export var titleContainer = "index-module--titleContainer--3IUwI";
export var titleText = "index-module--titleText--2rngP";
export var logo = "index-module--logo--3sd7s";
export var subtitle = "index-module--subtitle--J8TDx";
export var buttonsContainer = "index-module--buttonsContainer--21Hb1";
export var mapMuralButton = "index-module--mapMuralButton--3Dtgf";
export var mapMuralImage = "index-module--mapMuralImage--3SwRK";
export var mapMuralHeading = "index-module--mapMuralHeading--A3dnr";