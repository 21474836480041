import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import OSDViewer from '../components/OSDViewer';
import Layout from '../Layout';
import { hotspotsReadyState } from '../recoil/Atoms';
import {
  buttonsContainer,
  flexContainer,
  logo,
  mapMuralButton,
  mapMuralExplore,
  mapMuralHeading,
  mapMuralImage,
  subtitle,
  titleContainer,
  titleText,
} from './index.module.scss';

const IndexPage = ({ data, location }) => {
  const [viewer, setViewer] = useState(null);
  const hotspotsReady = useRecoilValue(hotspotsReadyState);

  // All osds are queried in order to populate the page link buttons
  const osds = data?.allStrapiOsds?.nodes;

  // Filter the osds to get the background canvas
  const osd = osds.find(({ osd_id }) => {
    return osd_id === 'mural';
  });

  const [activeHotspot, setActiveHotspot] = useState('');

  // On the homepage, hotspots are used for animation only.
  // They have already been filtered in graphql
  const hotspots = data?.allStrapiHotspots?.nodes;

  // GSAP sets new activeHotspots. OSD handles the animation.
  const hotspotTlRef = useRef();

  useEffect(() => {
    const shuffledHotspots = hotspots.sort(() => Math.random() - 0.5);

    hotspotTlRef.current = gsap.timeline({ paused: true, repeat: -1 });
    shuffledHotspots.forEach((hotspot, index) => {
      hotspotTlRef.current.call(
        () => setActiveHotspot(hotspot.hotspot_id),
        null,
        index * 12
      );
    });
    hotspotsReady && hotspotTlRef.current.play();
  }, [hotspots, hotspotsReady]);

  return (
    <Layout location={location}>
      <OSDViewer
        osdId={osd.osd_id}
        pageId={'home'}
        xmlData={osd.xml_data}
        viewer={viewer}
        // TODO replace with hotspotsReadyState?
        setViewer={setViewer}
        hotspots={hotspots}
        activeCategory={'ALL_MURAL'} // make the hotspots visible
        activeHotspot={activeHotspot}
      />
      <div className={`${flexContainer}`}>
        <div className={`${titleContainer}`}>
          <h1 className={`${titleText}`}>
            Camden
            <br /> History
          </h1>
          <div className={`${logo}`}>
            <StaticImage
              src="../images/camden-history-title.png"
              alt="Camden History"
            />
          </div>
          <h2 className={`${subtitle}`}>
            Explore the History of the <br />
            City and County of Camden
          </h2>
          <div className={`${buttonsContainer}`}>
            {osds.map(({ name, osd_id, thumbnail }) => (
              <Link
                className={`${mapMuralButton} `}
                to={`${osd_id}`}
                key={osd_id}
              >
                <GatsbyImage
                  image={getImage(thumbnail.localFile.childImageSharp)}
                  alt=""
                  className={`${mapMuralImage}`}
                  loading="eager"
                />
                <h3 className={`${mapMuralHeading}`}>
                  <span className="p block">
                    City {osd_id === 'map' && 'and County'} of Camden
                  </span>
                  {name}
                </h3>
                <span className={`${mapMuralExplore} buttonTextAction`}>
                  Explore
                </span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiOsds(sort: { fields: [osd_id], order: [ASC] }) {
      nodes {
        name
        osd_id
        thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80)
            }
          }
        }
        xml_data {
          Image {
            xmlns
            Format
            Overlap
            TileSize
            Size {
              Height
              Width
            }
            Url
          }
        }
      }
    }
    allStrapiHotspots(
      sort: { fields: [sort_order, title], order: [ASC, ASC] }
      filter: { osd: { osd_id: { eq: "mural" } } }
    ) {
      nodes {
        category
        hotspot_id
        hotspot_image {
          height
          width
          ext
          localFile {
            svgData
          }
        }
        osd {
          osd_id
        }
        x_position
        y_position
      }
    }
  }
`;

export default IndexPage;
